import React, { ReactNode } from "react"

import { Helmet } from "react-helmet"

import { Col, Row, Carousel } from "antd"

import "antd/dist/antd.css"

import * as styles from "./index.module.scss"

interface IcenteredLogo {
  imgPath: string
  style?: any
}

function CenteredLogo({ imgPath, style }: IcenteredLogo) {
  return (
    <div className={styles.centered_logo} style={{ ...style }}>
      <img src={imgPath} alt="centereimg" />
    </div>
  )
}

interface IflexBox {
  children: ReactNode | ReactNode[]
  style?: any
}
function FlexBox({ children, style }: IflexBox) {
  return (
    <div className={styles.flex_box} style={{ ...style }}>
      {children}
    </div>
  )
}

interface Iflex1 {
  title?: string
  details: string
  data?: {
    main: string
    extra: string
  }
}
function Flex1Item({ title, details, data }: Iflex1) {
  return (
    <div className={styles.flex1_item}>
      {data && (
        <div className={styles.data}>
          {data.main}
          <span>{data.extra}</span>
        </div>
      )}
      {title && (
        <div className={styles.second_title} style={{ marginBottom: "20px" }}>
          {title}
        </div>
      )}
      <div className={styles.text} style={{ lineHeight: "30px" }}>
        {details}
      </div>
    </div>
  )
}

interface Iflex {
  style?: any
  imgPath: string
  title: string
  details: string
}
function FlexItem({ style, imgPath, title, details }: Iflex) {
  return (
    <div className={styles.flex_item} style={{ ...style, textAlign: "center" }}>
      <img src={imgPath} alt="img" style={{ width: "70px", height: "70px" }} />
      <div className={styles.second_title} style={{ marginTop: "20px" }}>
        {title}
      </div>
      <div
        className={styles.text}
        style={{ marginTop: "20px", fontSize: "14px", textAlign: "center" }}
      >
        {details}
      </div>
    </div>
  )
}

interface Ititle {
  children: ReactNode | string
  style?: any
}
function Title({ children, style }: Ititle) {
  return (
    <div className={styles.title} style={{ ...style }}>
      {children}
    </div>
  )
}

function getImg(name: string) {
  return require(`@images/advertising/${name}.png`).default
}

//适配移动端的一些组件
interface IcardShadow {
  children: ReactNode | ReactNode[]
}
function CardShadow({ children }: IcardShadow) {
  return <div className={styles.card_shadow}>{children}</div>
}

export default function Advertising() {
  const facebookData1 = [
    {
      data: { main: "6000万", extra: "" },
      details: "活跃业务主页",
    },
    {
      data: { main: "20亿", extra: "+" },
      details: "每月活跃用户",
    },
    {
      data: { main: "11亿", extra: "+" },
      details: "移动设备访问量",
    },
    {
      data: { main: "43.82", extra: "%" },
      details: "全方位精准定位受众",
    },
  ]
  const facebookData2 = [
    {
      img: "facebookIcon1",
      title: "创建主页",
      details:
        "主页是客户发现品牌并与商家互动的位置，会直接与客户建立联系，可免费创建。",
    },
    {
      img: "facebookIcon2",
      title: "开通广告账户",
      details: "提交开户信息给销售代表，由代理公司帮忙提交。",
    },
    {
      img: "facebookIcon3",
      title: "创建广告",
      details: "由广告系列、广告组和广告组成，创建广告由创建广告系列开始。",
    },
    {
      img: "facebookIcon4",
      title: "确定推广目标",
      details: "侧重增加网站转化及吸引更多访客，根据目标对营销活动进行变更。",
    },
    {
      img: "facebookIcon5",
      title: "选择广告创意",
      details: "提供多种广告创意，根据目标选择合适的创意进行素材定制。",
    },
    {
      img: "facebookIcon6",
      title: "自定义受众",
      details:
        "寻找与现有客户和联系人相似的人群，在Facebook精准面向客户和联系人推广业务。",
    },
    {
      img: "facebookIcon7",
      title: "messenger",
      details:
        "聊天工具，允许与客户进行直接聊天，也可设置自动回复或创建智能助手。",
    },
    {
      img: "facebookIcon8",
      title: "效果评估",
      details: "监控广告效果，帮商家及时调整刚刚策略，最大化广告投放效果",
    },
  ]

  const googleData1 = [
    {
      data: { main: "33亿", extra: "" },
      details: "全球互联网用户",
    },
    {
      data: { main: "35亿", extra: "" },
      details: "每日Google搜索次数",
    },
    {
      data: { main: "90", extra: "%" },
      details: "覆盖全球90%互联网用户",
    },
  ]

  const googleData2 = [
    {
      img: "googleIcon1",
      title: "创意文案",
      details: "提供媒体优质文案，精准训练文案引擎，生成高质量广告创意。",
    },
    {
      img: "googleIcon2",
      title: "关键词",
      details: "全面覆盖关键词，精准匹配设置，有效提升成本效益。",
    },
    {
      img: "googleIcon3",
      title: "二次营销",
      details: "私域运营降低获客成本，促进客户购买，有效提高店铺转化。",
    },
    {
      img: "googleIcon4",
      title: "分析报告",
      details: "定时提供周报及月报，轻松掌握账户情况。",
    },
    {
      img: "googleIcon5",
      title: "专业服务",
      details: "多对一专业服务，第一时间解决客户问题。",
    },
  ]

  const bingData1 = [
    {
      title: "用户购买力高",
      details: "比平均互联网用户高101%，比Google高40%",
    },
    {
      title: "平台及终端覆盖广",
      details: "办公或娱乐，PC/平板/手机，全方位全场景广告完美注入",
    },
    {
      title: "定位精准",
      details: "可根据设备、地域、时间等进行定向广告投放，精准锁定目标用户",
    },
  ]

  const bingData2 = [
    {
      head: "全球",
      percentage: "10%",
      data: "110亿",
    },
    {
      head: "北美",
      percentage: "34%",
      data: "62.8亿",
    },
    {
      head: "欧洲",
      percentage: "9%",
      data: "20亿",
    },
    {
      head: "亚太地区",
      percentage: "4%",
      data: "20亿",
    },
    {
      head: "拉美",
      percentage: "6%",
      data: "7.41亿",
    },
  ]

  const ourserviseData = [
    {
      img: "serviceIcon1",
      title: "免费广告开户",
      details: "免费提供Facebook/Google/TikTok开户服务",
    },
    {
      img: "serviceIcon2",
      title: "广告优化服务",
      details: "专业优化师团队在线引导，只为让您的每一分钱都花得更值",
    },
    {
      img: "serviceIcon3",
      title: "广告创意服务",
      details: "提供广告创意支撑、广告素材制作及优化服务",
    },
    {
      img: "serviceIcon4",
      title: "全天广告审核服务",
      details: "实时追踪、专业审核、避免违规、事故及时处理",
    },
    {
      img: "serviceIcon5",
      title: "定制海外营销方案",
      details: "量身定制营销计划，付费/自然流量、私域流量、用户裂变有机组合",
    },
    {
      img: "serviceIcon6",
      title: "全程技术对接服务",
      details: "专业技术团队支持、专业的全链路解决方案",
    },
    {
      img: "serviceIcon7",
      title: "数据支持服务",
      details: "专业团队产出市场分析数据，用数据决胜未来",
    },
    {
      img: "serviceIcon8",
      title: "资深跨境服务团队",
      details: "跨境电商行业十余年老牌团队，深谙成交逻辑",
    },
  ]
  return (
    <>
      <Helmet title="推广服务-Yunstar" />
      <div className={styles.advertising}>
        {/* google */}

        <div id="google" />
        <div>
          <CenteredLogo
            imgPath={getImg("google")}
            style={{ marginTop: "60px" }}
          />
          <Row>
            <Col md={24} sm={0} xs={0}>
              <FlexBox style={{ marginTop: "30px" }}>
                {googleData1.map((item, index) => (
                  <Flex1Item
                    data={item.data}
                    details={item.details}
                    key={index}
                  />
                ))}
              </FlexBox>
            </Col>
            <Col md={0} sm={24} xs={24}>
              <Carousel>
                {googleData1.map((item, index) => (
                  <div key={index} className={styles.card_out}>
                    <CardShadow>
                      <Flex1Item data={item.data} details={item.details} />
                    </CardShadow>
                  </div>
                ))}
              </Carousel>
            </Col>
          </Row>
          <Title style={{ marginTop: "40px", color: "#02983B" }}>
            Google推广服务
          </Title>
          <FlexBox style={{ marginTop: "40px" }}>
            {googleData2.map((item, index) => (
              <FlexItem
                key={index}
                imgPath={getImg(item.img)}
                title={item.title}
                details={item.details}
              />
            ))}
          </FlexBox>
        </div>

        {/* facebook  */}
        <div id="facebook" />

        <div style={{ backgroundColor: "#F9FBFE", padding: "1px" }}>
          <CenteredLogo imgPath={getImg("facebook")} />
          <Row>
            <Col md={24} sm={0} xs={0}>
              <FlexBox style={{ marginTop: "30px" }}>
                {facebookData1.map((item, index) => (
                  <Flex1Item
                    data={item.data}
                    details={item.details}
                    key={index}
                  />
                ))}
              </FlexBox>
            </Col>
            <Col md={0} sm={24} xs={24}>
              <Carousel>
                {facebookData1.map((item, index) => (
                  <div key={index} className={styles.card_out}>
                    <CardShadow>
                      <Flex1Item data={item.data} details={item.details} />
                    </CardShadow>
                  </div>
                ))}
              </Carousel>
            </Col>
          </Row>

          <Title style={{ marginTop: "40px", color: "#02983B" }}>
          Facebook推广服务
        </Title>
        <FlexBox style={{ flexWrap: "wrap", marginTop: "40px" }}>
          {facebookData2.map((item, index) => (
            <FlexItem
              key={index}
              imgPath={getImg(item.img)}
              title={item.title}
              details={item.details}
            />
          ))}
        </FlexBox>
        </div>

        

        {/* bing */}
        <div id="bing" />
        <CenteredLogo imgPath={getImg("bing")} style={{ marginTop: "40px" }} />

        <Row>
          <Col md={24} sm={0} xs={0}>
            <FlexBox style={{ marginTop: "30px" }}>
              {bingData1.map((item, index) => (
                <Flex1Item
                  title={item.title}
                  details={item.details}
                  key={index}
                />
              ))}
            </FlexBox>
          </Col>
          <Col md={0} sm={24} xs={24}>
            <Carousel>
              {bingData1.map((item, index) => (
                <div key={index} className={styles.card_out}>
                  <CardShadow>
                    <Flex1Item title={item.title} details={item.details} />
                  </CardShadow>
                </div>
              ))}
            </Carousel>
          </Col>
        </Row>

        {/* bing 广告展示 */}
        <div className={styles.bing_second}>必应广告网络遍布全球</div>

        <Row>
          <Col md={24} sm={0} xs={0}>
            <div className={styles.earthContainer}>
              <div>
                <img src={getImg("earth")} alt="earth" />
              </div>
              <FlexBox
                style={{ position: "absolute", bottom: "-46px", width: "100%" }}
              >
                {bingData2.map(item => (
                  <div className={styles.bing_item} key={item.head}>
                    <div className={styles.head}>{item.head}</div>
                    <div
                      style={{
                        backgroundColor: "rgba(255,255,255,.8)",
                        padding: "1px",
                      }}
                    >
                      <div
                        className={styles.added}
                        style={{ marginTop: "30px" }}
                      >
                        {item.percentage}
                      </div>
                      <div className={styles.overhere}>搜索市场份额</div>
                      <div
                        className={styles.added}
                        style={{ marginTop: "20px" }}
                      >
                        {item.data}
                      </div>
                      <div
                        className={styles.overhere}
                        style={{ marginBottom: "30px" }}
                      >
                        月度搜索
                      </div>
                    </div>
                  </div>
                ))}
              </FlexBox>
            </div>
          </Col>
          <Col md={0} sm={24} xs={24}>
            <FlexBox
              style={{ padding: "30px 20px", justifyContent: "space-between" }}
            >
              {bingData2.map(item => (
                <div
                  className={styles.bing_item}
                  key={item.head}
                  style={{ width: "160px" }}
                >
                  <div className={styles.head}>{item.head}</div>
                  <div
                    style={{
                      backgroundColor: "rgba(255,255,255,.8)",
                      padding: "1px",
                    }}
                  >
                    <div className={styles.added} style={{ marginTop: "30px" }}>
                      {item.percentage}
                    </div>
                    <div className={styles.overhere}>搜索市场份额</div>
                    <div className={styles.added} style={{ marginTop: "20px" }}>
                      {item.data}
                    </div>
                    <div
                      className={styles.overhere}
                      style={{ marginBottom: "30px" }}
                    >
                      月度搜索
                    </div>
                  </div>
                </div>
              ))}
            </FlexBox>
          </Col>
        </Row>

        {/* 我们的服务*/}

        <div id="our" />
        <Row>
          <Col md={24} sm={0} xs={0}>
            <div className={styles.ourservise}>
              <img src={getImg("ourservise")} alt="" />
              <h1>专业的服务</h1>
              <h3>提供专业技术与服务团队，助力品牌快速走向世界</h3>
            </div>
          </Col>
          <Col md={0} sm={24} xs={24}>
            <div className={styles.ourservise_mobile}>
              <img src={getImg("ourservise_mobile")} alt="" />
              <h1>专业的服务</h1>
              <h3>提供专业技术与服务团队，助力品牌快速走向世界</h3>
            </div>
          </Col>
        </Row>

        <FlexBox style={{ flexWrap: "wrap", marginTop: "40px" }}>
          {ourserviseData.map((item, index) => (
            <FlexItem
              key={index}
              imgPath={getImg(item.img)}
              title={item.title}
              details={item.details}
            />
          ))}
        </FlexBox>
        {/* <div className={styles.consult_now}>
          <div className={styles.left}>立即咨询</div>
          <div className={styles.right}>bd@Yunstar.com</div>
        </div> */}
      </div>
    </>
  )
}
